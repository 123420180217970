<template>
  <error-content :code="$t('theservercrashed')" desc="Internal Server Erro" :src="src"/>
</template>

<script>
import error404 from '@/assets/images/errorpage/error-500.gif'
import errorContent from './error-content.vue'
export default {
  name: 'error_500',
  components: {
    errorContent
  },
  data () {
    return {
      src: error404
    }
  }
}
</script>
