<template>
  <div class="btn-box">
    <el-button type="text" @click="backHome">{{$t("backhome")}}</el-button>
    <el-button type="text" @click="backPrev">{{$t("backpreviouspage")}}({{ second }}s)</el-button>
  </div>
</template>

<script>
import './error.less'

export default {
  name: 'backBtnGroup',
  data() {
    return {
      second: 10,
      timer: null
    }
  },
  methods: {
    backHome() {
      this.$router.replace({
        name: 'Index'
      })
    },
    backPrev() {
      this.$router.go(-1)
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.second === 0) this.backHome()
      else this.second--
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
<style lang="less" scoped>
.btn-box {
  display: flex;
  justify-content: center;

  ::v-deep .el-button--text {
    span {
      border-bottom: 1px solid #018FD7;
    }
  }
}
</style>
